"use client";
import ArrowLeft from "@icons/ArrowLeft";
import { Button, ButtonProps, cn, Link } from "@nextui-org/react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();
  useEffect(() => {
    console.error(error);
  }, [error]);

  const ErrorButton = ({
    label,
    onClick,
    classNames,
    ...props
  }: ButtonProps & {
    label: string;
    onClick: () => void;
    classNames: string;
  }) => {
    return (
      <Button
        onClick={onClick}
        className={cn("w-[230px] h-[41px] rounded-[0.3125rem]", classNames)}
        {...props}
      >
        {label}
      </Button>
    );
  };

  return (
    <div className="h-dynamic-screen bg-black w-full overflow-auto flex flex-col xl:flex-row items-center justify-center px-[8rem] gap-[8rem] xl:gap-[8rem]">
      <div className="order-0 sm:order-1 flex flex-col items-start lg:items-center justify-start lg:justify-center gap-8">
        <div className="flex flex-col gap-3 items-start justify-center">
          <h2 className="font-semibold text-[16px] leading-[120%] text-[#5ef0a8]">
            Error
          </h2>
          <h1 className="text-[#FAF9F6] font-semibold text-[32px] sm:text-[60px] leading-[100%]">
            Something went wrong!
          </h1>
          <p className="text-[#b8b8b5] font-normal text-[16px] leading-[150%]">
            We were unable to process your request or the page you are looking
            for does not exist. Try again in a while or reach{" "}
            <Link
              href={"mailto:support@olyn.com"}
              className={"text-[#b8b8b5] underline"}
            >
              support
            </Link>
          </p>
        </div>
        <div className="flex flex-col sm:flex-row items-start justify-start gap-4">
          <ErrorButton
            label={"Go Back"}
            onClick={() => router.back()}
            startContent={<ArrowLeft size={20} color={"white"} />}
            classNames={
              "bg-transparent border-[#faf9f6] text-[#faf9f6] border-1"
            }
          />
          <ErrorButton
            label={"Home"}
            onClick={() => location.replace(location.origin)}
            classNames={"bg-[#faf9f6] text-tlg-black"}
          />
        </div>
      </div>
    </div>
  );
}
