import SvgIcon, { SvgIconProps } from "./SvgIcon";

const ArrowLeft: React.FC<SvgIconProps> = ({
  color = "#4D5761",
  size = 25,
  ...props
}) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      color="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M12.6663 8.0026H3.33301M3.33301 8.0026L7.99967 12.6693M3.33301 8.0026L7.99967 3.33594"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ArrowLeft;
